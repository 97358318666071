import React from 'react';
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import Container from '../components/Container/Container';
import Loader from '../components/Loader/Loader';
import MobileMenu from '../components/MobileMenu/MobileMenu';

function LayoutOutlet() {
  return (
    <>
      <Loader />
      <Container>
        <MobileMenu />
        <Suspense fallback={<Loader />}>
          <Outlet />
        </Suspense>
      </Container>
    </>
  );
}

export default LayoutOutlet;
