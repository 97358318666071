import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import ComingSoonPage from './pages/ComingSoonPage';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      {/* <App /> */}
      <ComingSoonPage />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
