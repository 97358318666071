import { Route, Routes } from 'react-router-dom';
import AboutUsPage from './pages/AboutUsPage';
import BlogClassic from './pages/BlogClassic';
import Inicio from './pages/Inicio';
import HomePage from './pages/HomePage';
import Layout from './pages/Layout';
// import LayoutRTL from './pages/LayoutRTL';
import LayoutComing from './pages/LayoutComing';
import LayoutVersion2 from './pages/LayoutVersion2';
import LayoutVersion3 from './pages/LayoutVersion3';
import NotFoundPage from './pages/NotFoundPage';
import LayoutOutlet from './pages/LayoutOutlet';
import { useState } from 'react';
import { v4 } from 'uuid';
import SlugLinkContext from './context/context';

import './font/demo-files/demo.css';
import './css/fancybox/jquery.fancybox.css';

import './css/revolution/css/settings.css';
import './css/revolution/css/layers.css';
import './css/revolution/css/navigation.css';

import './css/bootstrap-grid.min.css';
import './css/fontello.css';
import './css/owl.carousel.css';
import './css/style.css';
import './css/responsive.css';
import BlogMainPage from './pages/BlogMainPage';
import BlogMasonryPage from './pages/BlogMasonryPage';
import BlogSinglePage from './pages/BlogSinglePage';
import BlogTimelinePage from './pages/BlogTimelinePage';
import ComingSoonPage from './pages/ComingSoonPage';
import ContactPage from './pages/ContactPage';
import EventsListPage from './pages/EventsListPage';
import EventsMasonryPage from './pages/EventsMasonryPage';
import EventsMonthPage from './pages/EventsMonthPage';
import EventsSinglePage from './pages/EventsSinglePage';
import SermonsSinglePage from './pages/SermonsSinglePage';
import SermonsPafe from './pages/SermonsPage';
import HomePage2 from './pages/HomePage2';
import Header2Page from './pages/Header2Page';
import Header4Page from './pages/Header4Page';
import Header5Page from './pages/Header5Page';
import Header6Page from './pages/Header6Page';
import MinistriesV1Page from './pages/MinistriesV1Page';
import MinistriesV2Page from './pages/MinistriesV2Page';
import MinistriesSinglePage from './pages/MinistriesSinglePage';
import Portfolio2ColPage from './pages/Portfolio2ColPage';
import Portfolio3GridPage from './pages/Portfolio3GridPage';
import PortfolioGalleryPage from './pages/PortfolioGalleryPage';
import PortfolioInstagramPage from './pages/PortfolioInstagramPage';
import PortfolioMasonryPage from './pages/PortfolioMasonryPage';
import PortfolioSinglePage from './pages/PortfolioSinglePage';
import PortfolioVideoPage from './pages/PortfolioVideoPage';
import GiveServePage from './pages/GiveServePage';
import LeadershipPage from './pages/LeadershipPage';
import TeamMemberPage from './pages/TeamMemberPage';
import ShopAccountPage from './pages/ShopAccountPage';
import ShopCartPage from './pages/ShopCartPage';
import ShopCategoryPage from './pages/ShopCategoryPage';
import ShopCheckoutPage from './pages/ShopCheckoutPage';
import ShopSinglePage from './pages/ShopSinglePage';
import ShortcodesAccordionsPage from './pages/ShortcodesAccordionsPage';
import ShortcodesButtonsPage from './pages/ShortcodesButtonsPage';
import ShortcodesCallToActionPage from './pages/ShortcodesCallToActionPage';
import ShortcodesCountersPage from './pages/ShortcodesCountersPage';
import ShortcodesDividersPage from './pages/ShortcodesDividersPage';
import ShortcodesDropcapsPage from './pages/ShortcodesDropcapsPage';
import ShortcodesHeadingsPage from './pages/ShortcodesHeadingsPage';
import ShortcodesIconsPage from './pages/ShortcodesIconsPage';
import ShortcodesTablesPage from './pages/ShortcodesTablesPage';
import ShortcodesTabsPage from './pages/ShortcodesTabsPage';
import ShortcodesTeamPage from './pages/ShortcodesTeamPage';
import ShortcodesTestimonialsPage from './pages/ShortcodesTestimonialsPage';
import ShortcodesTooltipsPage from './pages/ShortcodesTooltipsPage';

const dataSlugLink = [
  {
    id: v4(),
    RTL: 'false',
    logo: '/',
    aboutus: 'aboutus',
    blog_classic: 'blog_classic',
    blog_main: 'blog_main',
    blog_masonry: 'blog_masonry',
    blog_single: 'blog_single',
    blog_timeline: 'blog_timeline',
    coming_soon: 'coming_soon',
    contact: 'contact',
    events_list: 'events_list',
    events_masonry: 'events_masonry',
    events_month: 'events_month',
    events_single: 'events_single',
    sermons: 'sermons',
    sermons_single: 'sermons_single',
    home_2: 'home_2',
    header_2: 'header_2',
    header_4: 'header_4',
    header_5: 'header_5',
    header_6: 'header_6',
    ministries_single: 'ministries_single',
    ministries_v1: 'ministries_v1',
    ministries_v2: 'ministries_v2',
    portfolio_2_col: 'portfolio_2_col',
    portfolio_3_grid: 'portfolio_3_grid',
    portfolio_gallery: 'portfolio_gallery',
    portfolio_instagram: 'portfolio_instagram',
    portfolio_masonry: 'portfolio_masonry',
    portfolio_single: 'portfolio_single',
    portfolio_video: 'portfolio_video',
    give_serve: 'give_serve',
    leadership: 'leadership',
    team_member: 'team_member',
    shop_account: 'shop_account',
    shop_cart: 'shop_cart',
    shop_category: 'shop_category',
    shop_checkout: 'shop_checkout',
    shop_single: 'shop_single',
    shortcodes_accordions: 'shortcodes_accordions',
    shortcodes_buttons: 'shortcodes_buttons',
    page_404: '404_page',
    anchor_footer: '#footer',
    anchor_footer2: 'home_2#footer',
  },
  {
    id: v4(),
    RTL: 'true',
    logo: '/rtl',
    aboutus: 'rtl_aboutus',
    blog_classic: 'rtl_blog_classic',
    blog_main: 'rtl_blog_main',
    blog_masonry: 'rtl_blog_masonry',
    blog_single: 'rtl_blog_single',
    blog_timeline: 'rtl_blog_timeline',
    coming_soon: 'rtl_coming_soon',
    contact: 'rtl_contact',
    events_list: 'rtl_events_list',
    events_masonry: 'rtl_events_masonry',
    events_month: 'rtl_events_month',
    events_single: 'rtl_events_single',
    sermons: 'rtl_sermons',
    sermons_single: 'rtl_sermons_single',
    home_2: 'rtl_home_2',
    page_404: 'rtl_404_page',
    anchor_footer: 'rtl#footer',
    anchor_footer2: 'rtl_home2#footer',
  },
];
function App() {
  const [slugLink] = useState(dataSlugLink);
  return (
    <>
      <SlugLinkContext.Provider value={slugLink}>
        <Routes>
          <Route exact path="/" element={<LayoutOutlet />}>
            <Route index element={<Layout children={<Inicio />} />} />
            <Route
              path="aboutus"
              element={<Layout children={<AboutUsPage />} />}
            />
            <Route
              path="blog_classic"
              element={<Layout  children={<BlogClassic />} />}
            />
            <Route
              path="blog_main"
              element={<Layout children={<BlogMainPage />} />}
            />
            <Route
              path="blog_masonry"
              element={<Layout children={<BlogMasonryPage />} />}
            />
            <Route
              path="blog_single"
              element={<Layout children={<BlogSinglePage />} />}
            />
            <Route
              path="blog_timeline"
              element={<Layout children={<BlogTimelinePage />} />}
            />
            <Route
              path="coming_soon"
              element={<LayoutComing children={<ComingSoonPage />} />}
            />
            <Route
              path="contact"
              element={<Layout children={<ContactPage />} />}
            />
            <Route
              path="events_list"
              element={<Layout children={<EventsListPage />} />}
            />
            <Route
              path="events_masonry"
              element={<Layout children={<EventsMasonryPage />} />}
            />
            <Route
              path="events_month"
              element={<Layout children={<EventsMonthPage />} />}
            />
            <Route
              path="events_single"
              element={<Layout children={<EventsSinglePage />} />}
            />
            <Route
              path="sermons_single"
              element={<Layout children={<SermonsSinglePage />} />}
            />
            <Route
              path="sermons"
              element={<Layout children={<SermonsPafe />} />}
            />
            <Route
              path="home_2"
              element={<LayoutVersion2 children={<HomePage2 />} />}
            />
            <Route path="header_2" element={<Header2Page />} />
            <Route path="header_4" element={<Header4Page />} />
            <Route path="header_5" element={<Header5Page />} />
            <Route path="header_6" element={<Header6Page />} />
            <Route
              path="ministries_single"
              element={<MinistriesSinglePage />}
            />
            <Route path="ministries_v1" element={<MinistriesV1Page />} />
            <Route path="ministries_v2" element={<MinistriesV2Page />} />
            <Route path="portfolio_2_col" element={<Portfolio2ColPage />} />
            <Route path="portfolio_3_grid" element={<Portfolio3GridPage />} />
            <Route
              path="portfolio_gallery"
              element={<PortfolioGalleryPage />}
            />
            <Route
              path="portfolio_instagram"
              element={<PortfolioInstagramPage />}
            />
            <Route
              path="portfolio_masonry"
              element={<PortfolioMasonryPage />}
            />
            <Route path="portfolio_single" element={<PortfolioSinglePage />} />
            <Route path="portfolio_video" element={<PortfolioVideoPage />} />
            <Route
              path="give_serve"
              element={<Layout children={<GiveServePage />} />}
            />
            <Route
              path="leadership"
              element={<Layout children={<LeadershipPage />} />}
            />
            <Route
              path="team_member"
              element={<Layout children={<TeamMemberPage />} />}
            />
            <Route
              path="shop_account"
              element={<Layout children={<ShopAccountPage />} />}
            />
            <Route
              path="shop_cart"
              element={<Layout children={<ShopCartPage />} />}
            />
            <Route
              path="shop_category"
              element={<Layout children={<ShopCategoryPage />} />}
            />
            <Route
              path="shop_checkout"
              element={<Layout children={<ShopCheckoutPage />} />}
            />
            <Route
              path="shop_single"
              element={<Layout children={<ShopSinglePage />} />}
            />
            <Route
              path="shortcodes_accordions"
              element={<ShortcodesAccordionsPage />}
            />
            <Route
              path="shortcodes_buttons"
              element={<ShortcodesButtonsPage />}
            />
            <Route
              path="shortcodes_call_to_action"
              element={<ShortcodesCallToActionPage />}
            />
            <Route
              path="shortcodes_counters"
              element={<ShortcodesCountersPage />}
            />
            <Route
              path="shortcodes_dividers"
              element={<ShortcodesDividersPage />}
            />
            <Route
              path="shortcodes_dropcaps"
              element={<ShortcodesDropcapsPage />}
            />
            <Route
              path="shortcodes_headings"
              element={<ShortcodesHeadingsPage />}
            />
            <Route path="shortcodes_icons" element={<ShortcodesIconsPage />} />
            <Route
              path="shortcodes_tables"
              element={<ShortcodesTablesPage />}
            />
            <Route path="shortcodes_tabs" element={<ShortcodesTabsPage />} />
            <Route path="shortcodes_team" element={<ShortcodesTeamPage />} />
            <Route
              path="shortcodes_testimonials"
              element={<ShortcodesTestimonialsPage />}
            />
            <Route
              path="shortcodes_tooltips"
              element={<ShortcodesTooltipsPage />}
            />
            <Route
              path="404_page"
              element={<Layout children={<NotFoundPage />} />}
            />
            {/* 
To use RTL, you need to rewrite the name of the classes. To enable RTL there is a hook in which you can enable the style connection. */}
            {/* <Route path="rtl" element={<LayoutRTL children={<HomePage />} />} /> */}
          </Route>
          <Route
            path="*"
            element={<Layout children={<NotFoundPage />} />}
          />
        </Routes>
      </SlugLinkContext.Provider>
    </>
  );
}

export default App;
