import React from 'react';

function ComingSoonPage() {
  return (
    <>
      <div id="content" className="page-content-wrap coming-soon">
        <div className="container">
          <div className="align-center">
            <div className="content-element3">
              <h1 className="title">Estamos chegando em breve</h1>

              <p className="text-size-big">
              Nosso website está em construção. Estaremos aqui em breve{' '}
              </p>
            </div>

            <div>
              <div
                className="countdown"
                data-year="2023"
                data-month="3"
                data-day="0"
                data-hours="0"
                data-minutes="0"
                data-seconds="0"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ComingSoonPage;
